import firebase from 'firebase';
var firebaseConfig = {
  apiKey: "AIzaSyBA9bt2Ual-boiv8UbJTac3L3iYxljfgMc",
  authDomain: "cetofinance-a8baf.firebaseapp.com",
  databaseURL: "https://cetofinance-a8baf-default-rtdb.firebaseio.com",
  projectId: "cetofinance-a8baf",
  storageBucket: "cetofinance-a8baf.appspot.com",
  messagingSenderId: "219865975254",
  appId: "1:219865975254:web:8edd7dfa6041fbfc5c7c77"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.firestore().settings({timestampsInSnapshots:true})

  export const f=firebase;
  export const database=firebase.database();
  export const storage=firebase.storage();
  export const auth=firebase.auth();
  export default firebase.firestore()