import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Dashnav/Navbar";
import { Link, Route, useHistory } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
function Refferals() {
  const navslide = () => {
    const nav = document.querySelector(".mobile-menu");
    nav.classList.toggle("open");
    console.log("leee");
  };

  const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] =
    useContext(GlobalContext);
  const navigate = useHistory();

  const [loading, setloading] = useState(true);
  const [amount, setamount] = useState("");
  const [dataready, setdataready] = useState(false);
  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false);
          navigate.push("/");
        }
      });
    }

    let myDate = new Date();
    console.log(myDate.getTime());
    console.log(addHoursToDate(myDate, 24).getTime());
    console.log(myDate);
    console.log(addHoursToDate(myDate, 1));
    new Date(new Date(myDate).setHours(myDate.getHours() + 20)).getTime();
  }, []);

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }


function handleCopyLink() {
  const copyInput = document.querySelector('.js-copy-input');
  copyInput.select();
  document.execCommand('copy');
  Swal.fire({
    icon: 'success',
    title: 'Link Copied!',
    text: 'You have successfully copied the referral link.',
    showConfirmButton: false,
    timer: 1500,
  });
}

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef.onSnapshot(function (doc) {
      if (doc.exists) {
        setdetails(doc.data());
        console.log(doc.data());
        setloading(false);
        setdataready(true);
      } else {
        console.log("No such document!");
      }
    });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    global.window && (global.window.location.href = "/home.html");
    const let3 = await navigate.push("/");
  };

  return (
    <div>
      {" "}
      <Helmet>
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content="" />

        <link
          rel="stylesheet"
          type="text/css"
          href="/dashboard/assets/css/app.css"
        />
        <script src="/dashboard/assets/js/app.js"></script>
      </Helmet>
      <div>
        <title>Cetofinance.com</title>
        {/*--------------- www.hyipcustomize.com ---------*/}
        <meta name="designer" href="https://hyipcustomize.com/" />
        {/*--------------- www.hyipcustomize.com ---------*/}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="description" content />
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
        <link rel="stylesheet" href="assets/css/app.css" />
        {/* Smartsupp Live Chat script */}
        <link
          type="text/css"
          rel="stylesheet"
          charSet="UTF-8"
          href="https://www.gstatic.com/_/translate_http/_/ss/k=translate_http.tr.qhDXWpKopYk.L.W.O/d=0/rs=AN8SPfp0QXhhaDDdjg_LgcSqoZiPEzC1tw/m=el_main_css"
        />
        <div className="loader hide">
          <img src="assets/images/spinner.svg" alt="" />
        </div>
        <main className="wrapper" id="app">
          {/* Profile layout*/}
          <div className="profile-layout js-sticky-wrapper">
            {/* sidebar*/}
            <Navbar />
            {/* Wrapper*/}
            <div className="profile-layout__wrapper">
              <header className="header-profile">
                <div className="container">
                  <div className="header-profile__row">
                    <a className="header-profile__logo" href="index.html">
                      <img src="assets/images/logo/logo.png" alt="" />
                    </a>
                    <h4 className="header-profile__slogan">
                      Crypto Investments{" "}
                    </h4>
                    <div className="header-profile__right">
                      <div className="total-balance">
                        Total Balance <span>$ </span>
                      </div>
                      <div className="select-language js-dropdown">
                        <a className="select-language__link">
                          <span>EN</span>
                        </a>
                        <div className="select-language__dropdown">
                          <ul>
                            <li>
                              <a href="?language=en">ENGLISH </a>
                            </li>
                            <li>
                              <a href="?language=es">SPANISH </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <a className="link-icon" href="?a=account">
                        <svg className="svg-icon">
                          <use href="assets/icons/sprite.svg#icon-003-shield" />
                        </svg>
                        <span>{userdetails.fullname}</span>
                      </a>
                    </div>
                  </div>
                </div>
                 {/* <div className="hamburger" onClick={navslide}>
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </div> */}
              </header>
              <article className="profile-layout__content">
                {/* Begin page*/}
                <section>
                  <div className="container">
                    <div className="account-statistics">
                      <div className="swiper-container js-swiper-statistics swiper-no-swiping swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-free-mode">
                        <div
                          className="swiper-wrapper"
                          id="swiper-wrapper-bbfa05da46a8bfa5"
                          aria-live="polite"
                        >
                          <div
                            className="swiper-slide swiper-slide-active"
                            role="group"
                            aria-label="1 / 4"
                            style={{ width: "341.4px", marginRight: "15px" }}
                          >
                            <div className="account-statistics-item">
                              <p className="account-statistics-item__title">
                                Total <strong>Invested</strong>
                              </p>
                              <p className="account-statistics-item__value">
                                <sub>$</sub>
                              </p>
                            </div>
                          </div>
                          <div
                            className="swiper-slide swiper-slide-next"
                            role="group"
                            aria-label="2 / 4"
                            style={{ width: "341.4px", marginRight: "15px" }}
                          >
                            <div className="account-statistics-item">
                              <p className="account-statistics-item__title">
                                Active <strong>Deposit</strong>
                              </p>
                              <p className="account-statistics-item__value">
                                <sub>$</sub>
                              </p>
                            </div>
                          </div>
                          <div
                            className="swiper-slide"
                            role="group"
                            aria-label="3 / 4"
                            style={{ width: "341.4px", marginRight: "15px" }}
                          >
                            <div className="account-statistics-item">
                              <p className="account-statistics-item__title">
                                Earned <strong>Profit</strong>
                              </p>
                              <p className="account-statistics-item__value">
                                <sub>$</sub>
                              </p>
                            </div>
                          </div>
                          <div
                            className="swiper-slide"
                            role="group"
                            aria-label="4 / 4"
                            style={{ width: "341.4px", marginRight: "15px" }}
                          >
                            <div className="account-statistics-item">
                              <p className="account-statistics-item__title">
                                Total <strong>Withdrawn</strong>
                              </p>
                              <p className="account-statistics-item__value">
                                <sub>$</sub>
                              </p>
                            </div>
                          </div>
                        </div>
                        <span
                          className="swiper-notification"
                          aria-live="assertive"
                          aria-atomic="true"
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n                        .sbmt {\n                            color: #000;\n                            background-image: -webkit-gradient(linear, left top, right top, from(#40efeb), to(#9ed163));\n                            background-image: -o-linear-gradient(left, #40efeb 0, #9ed163 100%);\n                            background-image: linear-gradient(90deg, #40efeb, #9ed163) padding: 0 30px;\n                            color: #000;\n                            background-color: #dbdddc;\n                            font-size: 15px;\n                            font-family: Manrope,\n                                sans-serif;\n                            cursor: pointer;\n                            display: -webkit-inline-box;\n                            display: -ms-inline-flexbox;\n                            display: inline-flex;\n                            -webkit-box-pack: center;\n                            -ms-flex-pack: center;\n                            justify-content: center;\n                            -webkit-box-align: center;\n                            -ms-flex-align: center;\n                            align-items: center;\n                            vertical-align: top;\n                            text-decoration: none;\n                            position: relative;\n                            border-radius: 6px;\n                            text-transform: uppercase;\n                            height: 40px;\n                            font-weight: 700;\n                            text-align: center;\n                            cursor: pointer;\n                        }\n\n                    ",
                  }}
                />
                <section>
                  <div className="container">
                    <div className="hr"></div>
                  </div>
                </section>
                <section>
                  <div className="container">
                    <div className="profile-block">
                      <div className="profile-block__top">
                        <h4 className="title-module">Referral Program </h4>
                      </div>
                      <form className="refferal-module">
                        <input
                          type="hidden"
                          name="form_id"
                          defaultValue={16912773545381}
                        />
                        <input
                          type="hidden"
                          name="form_token"
                          defaultValue="396e2bc12f38a72bbd474ad527ac1a75"
                        />
                        <div className="refferal-module__row">
                        <div className="refferal-module__col">
  <p className="block-title">Referral Link</p>
  <h4>click on link to copy</h4>
  <div className="copy-form js-copy"  onClick={handleCopyLink}>
    {!loading && (
      <input
      onClick={handleCopyLink}
        className="js-copy-input"
        type="text"
        defaultValue={`https://Cetofinance.com/a-signup.html?referralid=${userdetails.myReferralId}`}
      />
    )}
    <a className="js-copy-button" onClick={handleCopyLink}>
      <svg className="svg-icon">
        <use href="https://img.icons8.com/?size=80&id=42459&format=png" />
      </svg>
    </a>
  </div>
</div>
                          {/* <div className="refferal-module__col">
                            <p className="block-title">Referral Link </p>
                            <div className="copy-form js-copy">
                              {!loading && (
                                <input
                                  className="js-copy-input"
                                  type="text"
                                  defaultValue={`https://Cetofinance.com/a-signup.html?referralid=${userdetails.myReferralId} `}
                                />
                              )}
                              <a className="js-copy-button">
                                <svg className="svg-icon">
                                  <use href="assets/icons/sprite.svg#icon-copy" />
                                </svg>
                              </a>
                            </div>
                          </div> */}
                          <div className="refferal-module__col">
                            <p className="block-title">Invited</p>
                            {dataready &&
                              userdetails.referrals.map((referral, index) => (
                                <>
                                  <p key={index} className="invited-username">
                                    {referral.name}{" "}
                                    {/* Assuming each referral object has a 'username' property */}
                                  </p>
                                  Registered on{" "}
                                  <p className="invited-date">
                                    {new Date(
                                      referral.dateOfReferral.seconds * 1000
                                    ).toLocaleDateString()}
                                  </p>
                                </>
                              ))}
                          </div>
                          {/* 
                          <div className="refferal-module__col">
                            <p className="block-title">Invited</p>
                            {userdetails.referrals.map((referral, index) => (
                              <p key={index} className="invited-username">
                                {referral.name}{" "}
                              </p>
                            ))}
                          </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
                <section className="js-tabs">
                  <div className="container">
                    <div className="profile-block">
                      <div className="profile-block__content">
                        <div className="table-wrap">
                          <br />
                          <table
                            width={300}
                            cellSpacing={1}
                            cellPadding={1}
                            className="table"
                          >
                            {/* {dataready &&
                              userdetails.Deposithistory.map((obj, i) => (
                                <tr className="del237">
                                  <td>$ {obj.amt}</td>
                                  <td>{obj.coin}</td>
                                  <td>{new Date(obj.date).toDateString()}</td>
                                  <td>{obj.mode}</td>
                                </tr>
                              ))} */}
                            {dataready && (
                              <tbody>
                                <tr>
                                  <td className="item">Referrals:</td>
                                  <td className="item">
                                    {userdetails.referrals.length}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="item">Active referrals:</td>
                                  <td className="item">
                                    {userdetails.referrals.length}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="item">
                                    Total referral commission:
                                  </td>
                                  <td className="item">
                                    ${userdetails.total_referral_comissions}
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* <section class="banner-code">
      <div class="container">
          <div class="banner-code__image"><img src="/assets/bnr/125.gif" alt="images">
          </div>
          <div class="copy-form js-copy">
              <input class="js-copy-input" type="text" value="<a href=http://Cetofinance.com/?ref={userdetails.fullname}><img src=http://Cetofinance.com/assets/bnr/125.gif&quot;/></a>"><a class="js-copy-button">
                  <svg class="svg-icon">
                      <use href="assets/icons/sprite.svg#icon-copy"></use>
                  </svg></a>
          </div>
      </div>
  </section>
  <section class="banner-code">
      <div class="container">
          <div class="banner-code__image"><img src="/assets/bnr/468.gif" alt="images">
          </div>
          <div class="copy-form js-copy">
              <input class="js-copy-input" type="text" value="<a href=http://Cetofinance.com/?ref={userdetails.fullname}><img src=http://Cetofinance.com/assets/bnr/468.gif&quot;/></a>"><a class="js-copy-button">
                  <svg class="svg-icon">
                      <use href="assets/icons/sprite.svg#icon-copy"></use>
                  </svg></a>
          </div>
      </div>
  </section>
  <section class="banner-code">
      <div class="container">
          <div class="banner-code__image"><img src="/assets/bnr/728.gif" alt="images">
          </div>
          <div class="copy-form js-copy">
              <input class="js-copy-input" type="text" value="<a href=http://Cetofinance.com/?ref={userdetails.fullname}><img src=http://Cetofinance.com/assets/bnr/728.gif&quot;/></a>"><a class="js-copy-button">
                  <svg class="svg-icon">
                      <use href="assets/icons/sprite.svg#icon-copy"></use>
                  </svg></a>
          </div>
      </div>
  </section>
  <section class="banner-code">
      <div class="container">
          <div class="banner-code__image"><img src="/assets/bnr/1200.gif" alt="images">
          </div>
          <div class="copy-form js-copy">
              <input class="js-copy-input" type="text" value="<a href=http://Cetofinance.com/?ref={userdetails.fullname}><img src=http://Cetofinance.com/assets/bnr/1200.gif&quot;/></a>"><a class="js-copy-button">
                  <svg class="svg-icon">
                      <use href="assets/icons/sprite.svg#icon-copy"></use>
                  </svg></a>
          </div>
      </div>
  </section> */}
                <div id="button-up">
                  <i className="fa fa-chevron-up" />
                </div>
                <link
                  rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                />
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n    #button-up {\n        display: none;\n        position: fixed;\n        right: 20px;\n        bottom: 60px;\n        color: #000;\n        background-color: white;\n        text-align: center;\n        font-size: 30px;\n        padding: 3px 10px 10px 10px;\n        transition: .3s;\n        border-radius: 50px;\n        width: 50px;\n        height: 50px;\n        z-index: 9999;\n    }\n\n    #button-up:hover {\n        cursor: pointer;\n        background-color: #E8E8E8;\n        transition: .3s;\n    }\n\n",
                  }}
                />
                <section className="payments-logos">
                  <section className="payments-logos">
                    <div className="container">
                      <ul className="payments-logos__list">
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/pmusd.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/btc.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/eth.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/ltc.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/doge.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/trx.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/usdttrc20.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src="img/ps/svg/usdterc20.svg" alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>
                  {/* End page  */}
                </section>
              </article>
              <footer className="footer">
                <div className="container">
                  <div className="footer__row">
                    <div className="footer__col">
                      <div className="footer__nav-row">
                        <div className="footer__nav-col">
                          <h4 className="footer-title">Menu </h4>
                          <ul className="footer__nav">
                            <li>
                              <a href="?a=about">About us </a>
                            </li>
                            <li>
                              <a href="?a=investments">Investments</a>
                            </li>
                            <li>
                              <a href="?a=faq">FAQ</a>
                            </li>
                            <li>
                              <a href="?a=support">Contacts</a>
                            </li>
                          </ul>
                        </div>
                        <div className="footer__nav-col">
                          <h4 className="footer-title">Actions </h4>
                          <ul className="footer__nav">
                            <li>
                              <a
                                className="text-gradient-strong"
                                href="?a=deposit"
                              >
                                Make a Deposit
                              </a>
                            </li>
                            <li>
                              <a
                                className="text-gradient-strong"
                                href="?a=statistic"
                              >
                                Statistics
                              </a>
                            </li>
                            <li>
                              <a
                                className="text-gradient-strong"
                                href="?a=login"
                              >
                                Login
                              </a>
                            </li>
                            <li>
                              {" "}
                              {/* google translate */}
                              <style
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "\n    .goog-te-gadget{\n        font-size: 0px;\n    }\n    \n    #google_translate_element span{\n        display: none;\n    }\n    \n    select{\n        background: #203732;\n    }\n    \n    .goog-te-combo {\n        color: #fff;\n    }\n",
                                }}
                              />
                              <div>
                                <div id="google_translate_element">
                                  <div
                                    className="skiptranslate goog-te-gadget"
                                    dir="ltr"
                                    style={{}}
                                  >
                                    <div id=":0.targetLanguage">
                                      <select
                                        className="goog-te-combo"
                                        aria-label="Language Translate Widget"
                                      >
                                        <option value>Select Language</option>
                                        <option value="af">Afrikaans</option>
                                        <option value="sq">Albanian</option>
                                        <option value="am">Amharic</option>
                                        <option value="ar">Arabic</option>
                                        <option value="hy">Armenian</option>
                                        <option value="as">Assamese</option>
                                        <option value="ay">Aymara</option>
                                        <option value="az">Azerbaijani</option>
                                        <option value="bm">Bambara</option>
                                        <option value="eu">Basque</option>
                                        <option value="be">Belarusian</option>
                                        <option value="bn">Bengali</option>
                                        <option value="bho">Bhojpuri</option>
                                        <option value="bs">Bosnian</option>
                                        <option value="bg">Bulgarian</option>
                                        <option value="ca">Catalan</option>
                                        <option value="ceb">Cebuano</option>
                                        <option value="ny">Chichewa</option>
                                        <option value="zh-CN">
                                          Chinese (Simplified)
                                        </option>
                                        <option value="zh-TW">
                                          Chinese (Traditional)
                                        </option>
                                        <option value="co">Corsican</option>
                                        <option value="hr">Croatian</option>
                                        <option value="cs">Czech</option>
                                        <option value="da">Danish</option>
                                        <option value="dv">Dhivehi</option>
                                        <option value="doi">Dogri</option>
                                        <option value="nl">Dutch</option>
                                        <option value="eo">Esperanto</option>
                                        <option value="et">Estonian</option>
                                        <option value="ee">Ewe</option>
                                        <option value="tl">Filipino</option>
                                        <option value="fi">Finnish</option>
                                        <option value="fr">French</option>
                                        <option value="fy">Frisian</option>
                                        <option value="gl">Galician</option>
                                        <option value="ka">Georgian</option>
                                        <option value="de">German</option>
                                        <option value="el">Greek</option>
                                        <option value="gn">Guarani</option>
                                        <option value="gu">Gujarati</option>
                                        <option value="ht">
                                          Haitian Creole
                                        </option>
                                        <option value="ha">Hausa</option>
                                        <option value="haw">Hawaiian</option>
                                        <option value="iw">Hebrew</option>
                                        <option value="hi">Hindi</option>
                                        <option value="hmn">Hmong</option>
                                        <option value="hu">Hungarian</option>
                                        <option value="is">Icelandic</option>
                                        <option value="ig">Igbo</option>
                                        <option value="ilo">Ilocano</option>
                                        <option value="id">Indonesian</option>
                                        <option value="ga">Irish Gaelic</option>
                                        <option value="it">Italian</option>
                                        <option value="ja">Japanese</option>
                                        <option value="jw">Javanese</option>
                                        <option value="kn">Kannada</option>
                                        <option value="kk">Kazakh</option>
                                        <option value="km">Khmer</option>
                                        <option value="rw">Kinyarwanda</option>
                                        <option value="gom">Konkani</option>
                                        <option value="ko">Korean</option>
                                        <option value="kri">Krio</option>
                                        <option value="ku">
                                          Kurdish (Kurmanji)
                                        </option>
                                        <option value="ckb">
                                          Kurdish (Sorani)
                                        </option>
                                        <option value="ky">Kyrgyz</option>
                                        <option value="lo">Lao</option>
                                        <option value="la">Latin</option>
                                        <option value="lv">Latvian</option>
                                        <option value="ln">Lingala</option>
                                        <option value="lt">Lithuanian</option>
                                        <option value="lg">Luganda</option>
                                        <option value="lb">
                                          Luxembourgish
                                        </option>
                                        <option value="mk">Macedonian</option>
                                        <option value="mai">Maithili</option>
                                        <option value="mg">Malagasy</option>
                                        <option value="ms">Malay</option>
                                        <option value="ml">Malayalam</option>
                                        <option value="mt">Maltese</option>
                                        <option value="mi">Maori</option>
                                        <option value="mr">Marathi</option>
                                        <option value="mni-Mtei">
                                          Meiteilon (Manipuri)
                                        </option>
                                        <option value="lus">Mizo</option>
                                        <option value="mn">Mongolian</option>
                                        <option value="my">
                                          Myanmar (Burmese)
                                        </option>
                                        <option value="ne">Nepali</option>
                                        <option value="no">Norwegian</option>
                                        <option value="or">Odia (Oriya)</option>
                                        <option value="om">Oromo</option>
                                        <option value="ps">Pashto</option>
                                        <option value="fa">Persian</option>
                                        <option value="pl">Polish</option>
                                        <option value="pt">Portuguese</option>
                                        <option value="pa">Punjabi</option>
                                        <option value="qu">Quechua</option>
                                        <option value="ro">Romanian</option>
                                        <option value="ru">Russian</option>
                                        <option value="sm">Samoan</option>
                                        <option value="sa">Sanskrit</option>
                                        <option value="gd">Scots Gaelic</option>
                                        <option value="nso">Sepedi</option>
                                        <option value="sr">Serbian</option>
                                        <option value="st">Sesotho</option>
                                        <option value="sn">Shona</option>
                                        <option value="sd">Sindhi</option>
                                        <option value="si">Sinhala</option>
                                        <option value="sk">Slovak</option>
                                        <option value="sl">Slovenian</option>
                                        <option value="so">Somali</option>
                                        <option value="es">Spanish</option>
                                        <option value="su">Sundanese</option>
                                        <option value="sw">Swahili</option>
                                        <option value="sv">Swedish</option>
                                        <option value="tg">Tajik</option>
                                        <option value="ta">Tamil</option>
                                        <option value="tt">Tatar</option>
                                        <option value="te">Telugu</option>
                                        <option value="th">Thai</option>
                                        <option value="ti">Tigrinya</option>
                                        <option value="ts">Tsonga</option>
                                        <option value="tr">Turkish</option>
                                        <option value="tk">Turkmen</option>
                                        <option value="ak">Twi</option>
                                        <option value="uk">Ukrainian</option>
                                        <option value="ur">Urdu</option>
                                        <option value="ug">Uyghur</option>
                                        <option value="uz">Uzbek</option>
                                        <option value="vi">Vietnamese</option>
                                        <option value="cy">Welsh</option>
                                        <option value="xh">Xhosa</option>
                                        <option value="yi">Yiddish</option>
                                        <option value="yo">Yoruba</option>
                                        <option value="zu">Zulu</option>
                                      </select>
                                    </div>
                                    Powered by{" "}
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      <a
                                        className="VIpgJd-ZVi9od-l4eHX-hSRGPd"
                                        href="https://translate.google.com"
                                        target="_blank"
                                      >
                                        <img
                                          src="https://www.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_42x16dp.png"
                                          width="37px"
                                          height="14px"
                                          style={{ paddingRight: "3px" }}
                                          alt="Google Translate"
                                        />
                                        Translate
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* google translate end */}
                            </li>
                          </ul>
                        </div>
                        <div className="footer__nav-col hidden-sm">
                          <h4 className="footer-title">Language </h4>
                          <ul className="footer__nav">
                            <li className="is-active">
                              <a href="#">ENGLISH </a>
                            </li>
                            <li className>
                              <a href="#">SPANISH </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="footer__col"></div>
                  </div>
                  <div className="footer__bottom">
                    <p>
                      © Copyright, <span className="js-current-year">2023</span>{" "}
                      Cetofinance.com. All Rights Reserved
                    </p>
                    <ul>
                      <li>
                        <a href="?a=rules">Privacy Policy</a>
                      </li>
                      <li>License 13699699</li>
                    </ul>
                  </div>
                </div>
              </footer>
            </div>
            {/* Right bar*/}
            <div className="bar">
              {" "}
              <a className="logout-btn" onClick={logout}>
                <svg className="svg-icon">
                  <use href="assets/icons/sprite.svg#icon-logout" />
                </svg>
                <p className="logout-btn__name">Logout</p>
              </a>
            </div>
            {/* Mobile menu*/}
            <div className="mobile-menu">
              <div className="mobile-menu__top">
                {/* If auth*/}
                <a className="link-icon" href="profile-account.html">
                  <svg className="svg-icon">
                    <use href="assets/icons/sprite.svg#icon-003-shield" />
                  </svg>
                  <span>{userdetails.fullname}</span>
                </a>
                <div className="total-balance">
                  Total Balance <span>0 USD</span>
                </div>
              </div>
              {/* If Auth*/}
              <nav className="mobile-menu__nav">
                <ul>
                  <li className="is-active">
                    <a href="/dashboards">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-001-user-profile" />
                        </svg>
                      </span>
                      <span>Account </span>
                    </a>
                  </li>
                  <li>
                    <a href="/deposit">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-002-deposit" />
                        </svg>
                      </span>
                      <span>Invest </span>
                    </a>
                  </li>
                  <li>
                    <a href="deposit">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-002-deposit" />
                        </svg>
                      </span>
                      <span>Reinvest </span>
                    </a>
                  </li>
                  <li>
                    <a href="withdrawal">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-002-wallet" />
                        </svg>
                      </span>
                      <span>Withdraw funds </span>
                    </a>
                  </li>
                  <li>
                    <a href="/depositlist">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-double" />
                        </svg>
                      </span>
                      <span>My Deposits </span>
                    </a>
                  </li>
                  <li>
                    <a href="?a=earnings">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-double" />
                        </svg>
                      </span>
                      <span>Operations </span>
                    </a>
                  </li>
                  <li>
                    <a href="/refferals">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-scheme" />
                        </svg>
                      </span>
                      <span>Referrals </span>
                    </a>
                  </li>
                  <li>
                    <a href="/settings">
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-001-settings" />
                        </svg>
                      </span>
                      <span>Settings </span>
                    </a>
                  </li>
                  <li>
                    <a onClick={logout}>
                      <span className="mobile-menu__icon">
                        <svg className="svg-icon">
                          <use href="/dashboard/assets/icons/sprite.svg#icon-logout" />
                        </svg>
                      </span>
                      <span>Logout </span>
                    </a>
                  </li>
                </ul>
              </nav>
              <nav className="mobile-menu__nav">
                <ul>
                  <li>
                    <a href="?a=">Home page </a>
                  </li>
                  <li>
                    <a href="?a=about">About us</a>
                  </li>
                  <li>
                    <a href="?a=investments">Investments</a>
                  </li>
                  <li>
                    <a href="?a=faq">FAQ</a>
                  </li>
                  <li>
                    <a href="?a=contacts">Contacts</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          {/* Modals*/}
          {/* success*/}
          <div className="modal" id="successdep">
            <div className="modal-inner">
              <div className="modal-inner__close js-close-modal">
                <svg className="svg-icon">
                  <use href="assets/icons/sprite.svg#icon-cross" />
                </svg>
              </div>
              <div className="modal-header">
                <h3 className="title">Success! </h3>
              </div>
              <div id="depposit">
                <div className="modal-content">
                  <div className="typography">
                    <p id="infs" />
                  </div>
                  <div className="modal-icon">
                    {" "}
                    <img src="assets/images/check-line.svg" alt="" />
                  </div>
                </div>
                <div className="modal-footer modal-footer--center">
                  <button className="btn btn--primary js-close-modal">
                    Close{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal" id="success">
            <div className="modal-inner">
              <div className="modal-inner__close js-close-modal">
                <svg className="svg-icon">
                  <use href="assets/icons/sprite.svg#icon-cross" />
                </svg>
              </div>
              <div className="modal-header">
                <h3 className="title">Success! </h3>
              </div>
              <div className="modal-content">
                <div className="typography">
                  <p id="infs" />
                </div>
                <div className="modal-icon">
                  {" "}
                  <img src="assets/images/check-line.svg" alt="" />
                </div>
              </div>
              <div className="modal-footer modal-footer--center">
                <button className="btn btn--primary js-close-modal">
                  Close{" "}
                </button>
              </div>
            </div>
          </div>
          {/* error*/}
          <div className="modal" id="error">
            <div className="modal-inner">
              <div className="modal-inner__close js-close-modal">
                <svg className="svg-icon">
                  <use href="assets/icons/sprite.svg#icon-cross" />
                </svg>
              </div>
              <div className="modal-header">
                <h3 className="title">Error! </h3>
              </div>
              <div className="modal-content">
                <div className="typography">
                  <p id="inf" />
                </div>
                <div className="modal-icon">
                  {" "}
                  <img src="assets/images/cross-line.svg" alt="" />
                </div>
              </div>
              <div className="modal-footer modal-footer--center">
                <button className="btn btn--primary js-close-modal">
                  Close{" "}
                </button>
              </div>
            </div>
          </div>
          {/* gradient all svg*/}
          <svg className="primary-gradient">
            <linearGradient id="primary-gradient">
              <stop offset="0%" stopColor="#49ecdd" />
              <stop offset="100%" stopColor="#94d472" />
            </linearGradient>
          </svg>
        </main>
        <iframe
          id="chat-application-iframe"
          title="Smartsupp"
          aria-hidden="true"
          style={{
            display: "block",
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "1px",
            height: "1px",
            opacity: 0,
            border: "none",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <div
          id="goog-gt-tt"
          className="VIpgJd-yAWNEb-L7lbkb skiptranslate"
          style={{
            borderRadius: "12px",
            margin: "0 0 0 -23px",
            padding: 0,
            fontFamily: '"Google Sans", Arial, sans-serif',
          }}
          data-id
        >
          <div id="goog-gt-vt" className="VIpgJd-yAWNEb-hvhgNd">
            <div className=" VIpgJd-yAWNEb-hvhgNd-l4eHX-i3jM8c">
              <img
                src="https://fonts.gstatic.com/s/i/productlogos/translate/v14/24px.svg"
                width={24}
                height={24}
                alt=""
              />
            </div>
            <div className=" VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c">
              <div className="VIpgJd-yAWNEb-hvhgNd-IuizWc" dir="ltr">
                Original text
              </div>
              <div
                id="goog-gt-original-text"
                className="VIpgJd-yAWNEb-nVMfcd-fmcmS VIpgJd-yAWNEb-hvhgNd-axAV1"
              />
            </div>
            <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid ltr">
              <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od ltr" dir="ltr">
                <div className="VIpgJd-yAWNEb-hvhgNd-UTujCb">
                  Rate this translation
                </div>
                <div className="VIpgJd-yAWNEb-hvhgNd-eO9mKe">
                  Your feedback will be used to help improve Google Translate
                </div>
              </div>
              <div className="VIpgJd-yAWNEb-hvhgNd-xgov5 ltr">
                <button
                  id="goog-gt-thumbUpButton"
                  type="button"
                  className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
                  title="Good translation"
                  aria-label="Good translation"
                  aria-pressed="false"
                >
                  <span id="goog-gt-thumbUpIcon">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      focusable="false"
                      className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                    >
                      <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7H2v13h16c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM7 18H4V9h3v9zm14-7l-3 7H9V8l4.34-4.34L12 9h9v2z" />
                    </svg>
                  </span>
                  <span id="goog-gt-thumbUpIconFilled">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      focusable="false"
                      className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                    >
                      <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7v13h11c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM5 7H1v13h4V7z" />
                    </svg>
                  </span>
                </button>
                <button
                  id="goog-gt-thumbDownButton"
                  type="button"
                  className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
                  title="Poor translation"
                  aria-label="Poor translation"
                  aria-pressed="false"
                >
                  <span id="goog-gt-thumbDownIcon">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      focusable="false"
                      className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                    >
                      <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7h5V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zM17 6h3v9h-3V6zM3 13l3-7h9v10l-4.34 4.34L12 15H3v-2z" />
                    </svg>
                  </span>
                  <span id="goog-gt-thumbDownIconFilled">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      focusable="false"
                      className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                    >
                      <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zm16 0h4V4h-4v13z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div
              id="goog-gt-votingHiddenPane"
              className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
            >
              <form
                id="goog-gt-votingForm"
                action="//translate.googleapis.com/translate_voting?client=te"
                method="post"
                target="votingFrame"
                className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
              >
                <input type="text" name="sl" id="goog-gt-votingInputSrcLang" />
                <input type="text" name="tl" id="goog-gt-votingInputTrgLang" />
                <input
                  type="text"
                  name="query"
                  id="goog-gt-votingInputSrcText"
                />
                <input
                  type="text"
                  name="gtrans"
                  id="goog-gt-votingInputTrgText"
                />
                <input type="text" name="vote" id="goog-gt-votingInputVote" />
              </form>
              <iframe name="votingFrame" frameBorder={0} />
            </div>
          </div>
        </div>
        <div id="smartsupp-widget-container">
          {" "}
          <div
            data-testid="widgetButtonFrame"
            style={{
              borderRadius: "9999px",
              boxShadow:
                "rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.12) 0px 2px 32px 0px",
              height: "56px",
              position: "fixed",
              bottom: "24px",
              left: "initial",
              right: "12px",
              zIndex: 10000000,
              width: "109px",
            }}
          >
            <iframe
              id="widgetButtonFrame"
              title="Smartsupp widget button"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                border: "none",
                display: "block",
                textAlign: "left",
                margin: "0px",
                padding: "0px",
                top: "0px",
                left: "0px",
                opacity: 1,
              }}
              allowFullScreen
              scrolling="no"
            />
          </div>
        </div>
        <div className="VIpgJd-ZVi9od-aZ2wEe-wOHMyf">
          <div className="VIpgJd-ZVi9od-aZ2wEe-OiiCO">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="VIpgJd-ZVi9od-aZ2wEe"
              width="96px"
              height="96px"
              viewBox="0 0 66 66"
            >
              <circle
                className="VIpgJd-ZVi9od-aZ2wEe-Jt5cK"
                fill="none"
                strokeWidth={6}
                strokeLinecap="round"
                cx={33}
                cy={33}
                r={30}
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Refferals;
